// import {Routes, Route} from 'react-router-dom';
import ActivateAccount from '_components/auth/ActivateAccount';
// import React from 'react';
import RegisterTheme from '_components/auth/RegisterTheme';
import {AffiliatePrivateRoute} from '_components/AffiliatePrivateRoute';
import {AccountingPrivateRoute} from '_components/AccountingPrivateRoute'
import {ClientPrivateRoute} from '_components/ClientPrivateRoute';
import {PrivateRouteAdmin} from '_components';
import LoginTheme from '_components/auth/LoginTheme';
import React, {lazy, Suspense} from 'react';
import {Routes, Route} from 'react-router-dom';
import {useSelector} from 'react-redux';

export {App};

function App () {
  const {user: authUser} = useSelector (x => x.auth);

  const renderRoutes = () => {
    const sidebarLinks = require ('./partials/SidebarLinks.json');
  
    return Object.keys (sidebarLinks).flatMap (type =>
      sidebarLinks[type].map ((route, index) => {
        const LazyComponent = lazy (() =>
          import (`_components/${type}/${route.component}`)
        );
        const PrivateRoute = getPrivateRouteComponent (type);

        return (
          <Route
            key={index}
            path={`/${type}${route.path}`}
            element={
              <PrivateRoute>
                <Suspense
                  fallback={
                    <div>
                      <button
                        class="btn mt-4 btn-primary-light btn-wave"
                        type="button"
                        disabled
                      >
                        <span
                          class="spinner-border spinner-border-sm m-1 align-middle"
                          role="status"
                          aria-hidden="true"
                        />
                         Loading...
                      </button>
                    </div>
                  }
                >
                  <LazyComponent />
                </Suspense>
              </PrivateRoute>
            }
          />
        );
      })
    );
  };

  const getPrivateRouteComponent = type => {

    switch (type) {
      case 'client':
        return ClientPrivateRoute;
      case 'affiliate':
        return AffiliatePrivateRoute;
      case 'admin':
        return PrivateRouteAdmin;
      case 'accounting':
          return AccountingPrivateRoute;
      default:
        return Route;
    }
  };

  return (
    <div>
      <div>
        <Routes>
          {renderRoutes ()}
          <Route path="/" element={<LoginTheme />} />
          <Route path="/login" element={<LoginTheme />} />
          <Route path="/register" element={<RegisterTheme />} />
          <Route path="/activate/:id" element={<ActivateAccount />} />
        </Routes>
      </div>
    </div>
  );
}
