import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

/**
 * ActivateAccount component handles the activation of user accounts
 * by verifying the email with a verification code.
 * @returns {JSX.Element} ActivateAccount component
 */
const ActivateAccount = () => {
	// State to manage loading state
	const [isLoading, setIsLoading] = useState(true);

	// Get dynamic id from the URL
	const { id } = useParams();

	// Initialize navigate function to redirect to login page
	const navigate = useNavigate();

	useEffect(() => {
		// Function to verify email with verification code
		const verifyEmail = async () => {
			try {
				// Fetch API to verify email
				const response = await fetch(`${process.env.REACT_APP_API_URL}/api/public/verify-email`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ verificationCode: id }),
				});

				// Parse response data
				const data = await response.json();

				// Set loading state to false after fetching
				setIsLoading(false);

				// Show success or error message based on response status
				if (response.status === 200) {
					Swal.fire({
						title: "Success",
						text: data.message,
						icon: "success",
						confirmButtonText: "Go to Login",
					}).then(() => {
						navigate("/login"); // Redirect to login page after success
					});
				} else {
					Swal.fire({
						title: "Error",
						text: data.message,
						icon: "error",
						confirmButtonText: "Go to Login",
					}).then(() => {
						navigate("/login"); // Redirect to login page after error
					});
				}
			} catch (error) {
				// Set loading state to false if error occurs
				setIsLoading(false);
				// Show error message if there's an error
				Swal.fire("Error", "An error occurred while verifying email.", "error");
				console.error("Error:", error); // Log error to console
			}
		};

		verifyEmail();
	}, [id]); // Dependency array to run useEffect only when 'id' changes

	// Render loading spinner while fetching data
	return (
		<div className="container mt-5">
			<ClipLoader loading={isLoading} size={35} />
		</div>
	);
};

export default ActivateAccount;
