import { NavLink, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { loginSuccess } from '_store/login.slice';
import { useNavigate } from "react-router-dom";

const Header = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();
  const { user: authUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [topBarShow, setTopBarShow] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (sidebarOpen && !event.target.closest('#sidebar') && !event.target.closest('.left-toggle')) {
        toggleSidebar();
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [sidebarOpen]);

  useEffect(() => {
      setTopBarShow(localStorage.getItem("impersoateUsertoken") ? true : false);
  }, [topBarShow]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
    const sidebar = document.getElementById('sidebar');
    sidebar.classList.toggle('sidebar-open');
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
    const sidebar = document.getElementById('sidebar');
    sidebar.classList.remove('sidebar-open');
  };

  const isClientPath = location.pathname.startsWith('/client');
  const isAffiliatePath = location.pathname.startsWith('/affiliate');

  const logoutImporsonateUser = () => {

    const user = JSON.parse(localStorage.getItem("mainUser"));
    const token = localStorage.getItem("mainToken");
    localStorage.setItem('token', token);
    localStorage.removeItem("impersoateUsertoken");
    localStorage.removeItem("impersoateUser");
    dispatch(loginSuccess(user));
    navigate("/admin/users");
  }

  return (
    <>
    {topBarShow &&
            <div className='topBar'>
            <span className='d-inline-block mt-1'>You are logged in as a <strong>{authUser?.full_name}</strong></span>

            <span className='exit-user' title='Exit' onClick={logoutImporsonateUser}>
              <i className="ri-logout-box-r-line"></i>
            </span>
            </div>
        }
      <header className="app-header">
        
       
        
        <div className="main-header-container container-fluid">
          <div className="header-content-left">
            <a 
              aria-label="Hide Sidebar" 
              className="sidemenu-toggle left-toggle header-link animated-arrow hor-toggle horizontal-navtoggle mx-0 my-auto" 
              data-bs-toggle="sidebar" 
              data-bs-target="#sidebar" 
              onClick={toggleSidebar}
            >
              <span />
            </a>
            <div className="header-element">
              <img
                src="../assets/images/logo.png"
                alt="logo"
                width={100}
                className="mobile-logo"
              />
            </div>
          </div>

          <div className="header-content-right">
            {/* {isClientPath && (
              <div className="header-element">
                <NavLink to="/client/setting" className="side-menu__item">
                  <a className="header-link pointer">
                    <i className="fe fe-settings header-link-icon" />
                  </a>
                </NavLink>
              </div>
            )} */}

            {isAffiliatePath && (
              <div className="header-element">
                <NavLink to="/affiliate/payout-method" className="side-menu__item">
                  <a className="header-link pointer">
                    <i className="fe fe-settings header-link-icon" />
                  </a>
                </NavLink>
              </div>
            )}

            <div className="header-element d-none d-sm-block">
              <a href="#" className="header-link" data-bs-toggle="offcanvas" data-bs-target="#offcanvassidebar">
                <i className="fe fe-menu header-link-icon" />
              </a>
            </div>
          </div>
        </div>
      </header>

      {sidebarOpen && (
        <button className="close-button" onClick={closeSidebar}>
          <i className="fe fe-x" />
        </button>
      )}
    </>
  );
};

export default Header;
