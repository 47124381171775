import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ErrorMessage, useFormik } from "formik";
import ClipLoader from "react-spinners/ClipLoader";
import { registerUser, registrationReset } from "../../_store/register.slice";
import LoaderComponent from "partials/Loader";

/**
 * Register component for user registration.
 *
 * Displays a registration form allowing users to sign up with their full name, email, and password.
 *
 * @returns {JSX.Element} Register component
 */
const RegisterTheme = () => {
	const dispatch = useDispatch();

	// Redux selectors
	const alertVariant = useSelector((state) => state.register.alertVariant);
	const alertMessage = useSelector((state) => state.register.alertMessage);
	const isLoading = useSelector((state) => state.register.isLoading);

	/**
	 * Reset registration state on component mount.
	 */
	useEffect(() => {
		dispatch(registrationReset());
	}, []);

	/**
	 * Handle form submission.
	 *
	 * Dispatches registerUser action with form values.
	 *
	 * @param {object} values - Form values
	 * @param {object} setSubmitting - Form submission status
	 */
	const handleSubmit = async (values, { setSubmitting }) => {
		try {
			setSubmitting(true);
			dispatch(registerUser(values));
		} catch (error) {
			// Handle error
		} finally {
			setSubmitting(false);
		}
	};

	// Formik configuration
	const formik = useFormik({
		initialValues: {
			fullName: "",
			email: "",
			password: "",
			roleId: 4,
		},
		onSubmit: handleSubmit,
		validate: (values) => {
			const errors = {};
			if (!values.fullName) {
				errors.fullName = "Full name is required";
			}
			if (!values.email) {
				errors.email = "Email is required";
			} else if (!/^\S+@\S+\.\S+$/.test(values.email)) {
				errors.email = "Invalid email address";
			}
			if (!values.password) {
				errors.password = "Password is required";
			}
			return errors;
		},
	});

	// JSX rendering
	return (
		<div className="login-img" style={{ backgroundImage: 'url("../../assets/images/background-login.png")', backgroundSize: 'cover',}}>
			<LoaderComponent />

			<div className="page">
				{/* PAGE */}
				<div className="page login-page">
					<div>
						{/* CONTAINER OPEN */}

						<div className="col col-login mx-auto mt-7">
							<div className="text-center">
							<img src="../assets/images/logo.png" className="header-brand-img" alt="" />
							</div>
						</div>

						<div className="container-login100">
							<div className="card  wrap-login100 p-0">
								<div className="col-md-12 col-login mx-auto mt-7">
									<div className="text-center">
										{alertMessage && (
											<p className={`alert ${alertVariant}`} role="alert">
												{alertMessage}
											</p>
										)}{" "}
									</div>
								</div>
								<div className="card-body">
									<form onSubmit={formik.handleSubmit} className="login100-form validate-form">
										<span className="login100-form-title">Register</span>
										<div className="wrap-input100 ">
											<input
												type="text"
												id="fullName"
												name="fullName"
												className={`form-control input100 ${formik.touched.fullName && formik.errors.fullName ? "is-invalid" : ""}`}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values.fullName}
												placeholder="Full Name"
											/>
											<span className="focus-input100" />
											<span className="symbol-input100">
												<i className="ri-user-fill" aria-hidden="true" />
											</span>
										</div>
										{formik.touched.fullName && formik.errors.fullName && <div className={`invalid-feedback ${formik.touched.fullName ? "d-block" : ""}`}>{formik.errors.fullName}</div>}

										<div className="wrap-input100 ">
											<input
												type="email"
												id="email"
												name="email"
												className={`form-control input100 ${formik.touched.email && formik.errors.email ? "is-invalid" : ""}`}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values.email}
												placeholder="Email"
											/>{" "}
											<span className="focus-input100" />
											<span className="symbol-input100">
												<i className="ri-mail-fill" aria-hidden="true" />
											</span>
										</div>
										{formik.touched.email && formik.errors.email && <div className={`invalid-feedback ${formik.touched.email ? "d-block" : ""}`}>{formik.errors.email}</div>}

										<div className="mb-3 wrap-input100 validate-input" data-bs-validate="Password is required">
											<input
												type="password"
												id="password"
												name="password"
												className={`form-control input100 ${formik.touched.password && formik.errors.password ? "is-invalid" : ""}`}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values.password}
												placeholder="Password"
											/>{" "}
											<span className="focus-input100" />
											<span className="symbol-input100">
												<i className="ri-lock-fill" aria-hidden="true" />
											</span>
										</div>
										{formik.touched.password && formik.errors.password && <div className={`invalid-feedback ${formik.touched.password ? "d-block" : ""}`}>{formik.errors.password}</div>}

										{/* <button type="submit" disabled={isSubmitting} className="btn btn-primary">
													{isSubmitting && <span className="spinner-border spinner-border-sm me-1" />}
													Login
												</button> */}
										<label className="custom-control custom-checkbox mt-4">
											<input className="form-check-input" type="checkbox" id="checkboxNoLabel" value="" aria-label="..." />
											<span className="custom-control-label ms-1">
												Agree the{" "}
												<a href="#" className="text-primary">
													terms and policy
												</a>
											</span>
										</label>
										<div className="container-login100-form-btn">
											<button type="submit" disabled={isLoading} className="login100-form-btn btn-primary">
												Register <ClipLoader loading={isLoading} size={"13px"} color="white" />
											</button>
										</div>
										<div className="text-center pt-3">
											<p className="text-dark mb-0">
												Already have a User?{" "}
												<Link className="text-primary ms-1" to="/login">
													Login
												</Link>
											</p>

										</div>
									</form>
								</div>
								{/*<div className="card-footer border-top">*/}
								{/*	<div className="d-flex justify-content-center my-3">*/}
								{/*		<a href="javascript:void(0);" className="social-login  text-center">*/}
								{/*			<i className="ri-google-fill" />*/}
								{/*		</a>*/}
								{/*		<a href="javascript:void(0);" className="social-login  text-center mx-4">*/}
								{/*			<i className="ri-facebook-fill" />*/}
								{/*		</a>*/}
								{/*		<a href="javascript:void(0);" className="social-login  text-center">*/}
								{/*			<i className="ri-twitter-fill" />*/}
								{/*		</a>*/}
								{/*	</div>*/}
								{/*</div>*/}
							</div>
						</div>
						{/* CONTAINER CLOSED */}
					</div>
				</div>
				{/* End PAGE */}
				{/* @SPK@include("partials/headersearch_modal.html") */}
				{/* @SPK@include("partials/footer.html") */}
			</div>
		</div>
	);
};

export default RegisterTheme;
