import React, { useEffect } from "react";
import Header from "partials/Header";
import LoaderComponent from "partials/Loader";
import Footer from "partials/Footer";
import SideBarOffCanvas from "partials/SideBarOffCanvas";
import Sidebar from "partials/Sidebar";
import sidebarLinks from "../../../partials/SidebarLinks.json";


const links = {'accounting' :sidebarLinks['accounting']};



const AccountingLayout = ({ children }) => {


	return (
		<>
			<LoaderComponent />
			<div className={`page`}>
				<Header />
				<Sidebar sidebarLinks={links}/>
				{/* Start::app-content */}
				<div className="main-content app-content">
					<div className="container-fluid">
						
						{children}
					</div>
				</div>
				{/* End::app-content */}

				<Footer />
				<SideBarOffCanvas />
			</div>
		</>
	);
};

export default AccountingLayout;
