import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { authReducer } from "./login.slice";
import { usersReducer } from "./users.slice";
import { createBrowserHistory } from "history";
import registrationReducer from "./register.slice";

export const history = createBrowserHistory();

const rootPersistConfig = {
	key: "root",
	storage,
};

const userPersistConfig = {
	key: "user",
	storage,
};

// Create a persisted reducer for the userReducer
const persistedUserReducer = persistReducer(userPersistConfig, authReducer);

const persistedRootReducer = persistReducer(
	rootPersistConfig,
	combineReducers({
		auth: persistedUserReducer,
		users: usersReducer,
		register: registrationReducer,


	})
);

export default persistedRootReducer;
