import {useEffect} from 'react';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import {useSelector, useDispatch} from 'react-redux';
import * as Yup from 'yup';
import {Link, useNavigate} from 'react-router-dom'; // Import useNavigate from react-router-dom

import {login, loginReset} from '_store/login.slice';
import LoaderComponent from 'partials/Loader';
import {ClipLoader} from 'react-spinners';

const LoginTheme = () => {
  const dispatch = useDispatch ();
  const authUser = useSelector (x => x.auth.user);
  const authError = useSelector (x => x.auth.error);

  const navigate = useNavigate (); // Use useNavigate hook to access navigation function

  useEffect (
    () => {
      // Redirect based on authUser
      if (authUser) {
        // Redirect users based on their roles
        // For affiliate users
        if (authUser?.administrator === 1) {
          navigate ('/admin/dashboard');
        }
        // For admin users
        else if (authUser?.affiliate_approved === 1) {
          navigate ('/affiliate/dashboard');
        }
        // For client users
        else {
          navigate ('/client/passes');
        }
      } else {
        navigate ('/login'); // Use navigate to redirect
      }
    },
    [authUser, navigate]
  ); // Add navigate to the dependency array

  const handleSubmit = async (values, {setSubmitting}) => {
    try {
      console.log ({values});
      await dispatch (login (values));
      setSubmitting (false);
    } catch (error) {
      setSubmitting (false);
    }
  };

  useEffect (() => {
    dispatch (loginReset ());
  }, []);

  const validationSchema = Yup.object ().shape ({
    email: Yup.string ()
      .email ('Please use correct email')
      .required ('Email is required'),
    password: Yup.string ().required ('Password is required'),
  });

  return (
    <div
      className="login-img"
      style={{
        backgroundImage: 'url("../../assets/images/background-login.png")',
        backgroundSize: 'cover',
      }}
    >
      <LoaderComponent />

      <div className="page">
        {/* PAGE */}
        <div className="page login-page">
          <div>
            {/* CONTAINER OPEN */}
            <div className="col col-login mx-auto mt-7">
              <div className="text-center">
                <img
                  src="../assets/images/logo.png"
                  className="header-brand-img"
                  alt=""
                />
              </div>
            </div>
            <div className="container-login100">
              <div className="card  wrap-login100 p-0">
                <div className="col-md-12 col-login mx-auto mt-7">
                  <div className="text-center">
                    {authError &&
                      <p className={`alert alert-danger`} role="alert">
                        {authError}
                      </p>}{' '}
                  </div>
                </div>
                <div className="card-body">
                  <Formik
                    initialValues={{email: '', password: ''}}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({isSubmitting, errors, touched}) => (
                      <Form className="login100-form validate-form">
                        <span className="login100-form-title">Login</span>
                        <div className="wrap-input100 ">
                          <Field
                            type="text"
                            id="email"
                            name="email"
                            className={`form-control input100 ${touched.email && errors.email ? 'is-invalid' : ''}`}
                            placeholder="Email"
                          />
                          <span className="focus-input100" />
                          <span className="symbol-input100">
                            <i className="ri-mail-fill" aria-hidden="true" />
                          </span>
                        </div>
                        <ErrorMessage
                          name="email"
                          component="div"
                          className={`invalid-feedback ${touched.email ? 'd-block' : ''}`}
                        />

                        <div
                          className="mb-3 wrap-input100 validate-input"
                          data-bs-validate="Password is required"
                        >
                          <Field
                            type="password"
                            id="input2"
                            name="password"
                            className={`form-control input100 ${touched.password && errors.password ? 'is-invalid' : ''}`}
                            placeholder="Password"
                          />
                          <span className="focus-input100" />
                          <span className="symbol-input100">
                            <i className="ri-lock-fill" aria-hidden="true" />
                          </span>
                        </div>
                        <ErrorMessage
                          name="password"
                          component="div"
                          className={`invalid-feedback ${touched.password ? 'd-block' : ''}`}
                        />

                        {/* <button type="submit" disabled={isSubmitting} className="btn btn-primary">
													{isSubmitting && <span className="spinner-border spinner-border-sm me-1" />}
													Login
												</button> */}
                        <div className="text-end pt-1">
                          <p className="mb-0">
                            <a href="#" className="text-primary ms-1">
                              Forgot Password?
                            </a>
                          </p>
                        </div>
                        <div className="container-login100-form-btn">
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="login100-form-btn btn-primary"
                          >
                            Sign in
                            {' '}
                            <ClipLoader
                              loading={isSubmitting}
                              size={'13px'}
                              color="white"
                            />
                          </button>
                        </div>
                        <div className="text-center pt-3">
                          <p className="text-dark mb-0">
                            Not a User? {'        '}
                            <Link to="/register" className="text-primary ms-1">
                              Sign Up Free
                            </Link>
                          </p>

                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
            {/* CONTAINER CLOSED */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginTheme;
