import { history } from "_store";
import axios from "axios";



// Action Types
const LOGIN_REQUEST = "LOGIN_REQUEST";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGIN_FAILURE = "LOGIN_FAILURE";
const LOGOUT = "LOGOUT";
const LOGIN_RESET = "LOGIN_RESET";

/**
 * Action creator to reset login state.
 * @returns {Object} Action object
 */
export const loginReset = () => ({
	type: LOGIN_RESET,
});

/**
 * Action creator for login request.
 * @returns {Object} Action object
 */
export const loginRequest = () => ({
	type: LOGIN_REQUEST,
});

/**
 * Action creator for successful login.
 * @param {Object} user - User object
 * @returns {Object} Action object
 */
export const loginSuccess = (user) => ({
	type: LOGIN_SUCCESS,
	payload: user,
});


/**
 * Action creator for login failure.
 * @param {string} error - Error message
 * @returns {Object} Action object
 */
export const loginFailure = (error) => ({
	type: LOGIN_FAILURE,
	payload: error,
});

/**
 * Action creator for user logout.
 * @returns {Object} Action object
 */
export const logout = () => ({
	type: LOGOUT,
});

/**
 * Async action creator for user login.
 * @param {Object} credentials - User credentials (email and password)
 * @returns {Function} Asynchronous action
 */
export const login = ({ email, password }) => {
	return async (dispatch) => {
		dispatch(loginReset());
		dispatch(loginRequest());
		try {
			const baseUrl = `${process.env.REACT_APP_API_URL}`;
			const response = await axios.post(`${baseUrl}/auth/login`, { email, password });
			let user = response?.data?.data?.user;

			if (response.data.result === 'success' && user) {

				dispatch(loginSuccess(user));
				localStorage.setItem("token", JSON.stringify(response.data.data.tokens));
				localStorage.setItem("mainUser", JSON.stringify(user));
				localStorage.setItem("mainToken", JSON.stringify(response.data.data.tokens));
				

				let path = '/';

				// Redirect users based on their roles
				
				// For admin users
				if (user?.administrator === 1) {
					path = "/admin/dashboard";
				}
				// For affiliate users
				else if (user?.affiliate_approved === 1) {
					path = "/affiliate/dashboard";
				}
				// For client users
				else {
					path = "/client/passes";
				}
				
				const { from } = history.location.state || { from: { pathname: path } };
				history.push(from);

			} else {
				console.log(response.data.message);
				dispatch(loginFailure(response.data.message));
			}
		} catch (error) {
			console.log(error?.response?.message);
			dispatch(loginFailure(error?.response?.data?.message || "Something went wrong!"));
		}
	};
};

// Reducer
const initialState = {
	isLoading:false,
	user: JSON.parse(localStorage.getItem("user")),
	error: null,
};

/**
 * Reducer for authentication state.
 * @param {Object} state - Current state
 * @param {Object} action - Action object
 * @returns {Object} New state
 */
export const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOGIN_REQUEST:
			return {
				...state,
				isLoading:true,
				error: null,
			};
		case LOGIN_SUCCESS:
			return {
				...state,
				user: action.payload,
				error: null,
				isLoading:false,

			};
		case LOGIN_FAILURE:
			return {
				...state,
				error: action.payload,
				isLoading:false,

			};
		case LOGOUT:
			localStorage.clear();

			history.push("/login");
			return {
				...state,
				user: null,
				error: null,
			};
		case LOGIN_RESET:
			localStorage.removeItem("mainUser");
			localStorage.removeItem("mainToken");
			localStorage.removeItem("impersoateUsertoken");
			localStorage.removeItem("impersoateUser");		
				
			return {
				user: JSON.parse(localStorage.getItem("user")),
				error: null,
				isLoading:false,

			};
		default:
			return state;
	}
};
