import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { history } from "_helpers";
import { useEffect } from "react";
import ClientLayout from "./client/layout/ClientLayout";

export { ClientPrivateRoute };

function ClientPrivateRoute({ children }) {
	const { user: authUser } = useSelector((x) => x.auth);
	
	if (!authUser) {
		return <Navigate to="/login" state={{ from: history.location }} />;
	}
	return<ClientLayout>{children}</ClientLayout>	;
}
