import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { history } from "_helpers";
import AdminLayout from "./admin/layout/AdminLayout";

export { PrivateRouteAdmin };

function PrivateRouteAdmin({ children }) {
	const { user: authUser } = useSelector((x) => x.auth);
	
	if (!authUser) {
		return <Navigate to="/" state={{ from: history.location }} />;
	}
	return (
		<>
			<AdminLayout>{(authUser && authUser?.administrator !== 1) ? <h1>Not Allowed</h1> : children}</AdminLayout>
		</>
	);
}
