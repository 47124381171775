import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { history } from "_helpers";
import AccountingLayout from "./accounting/layout/AccountingLayout";

export { AccountingPrivateRoute };

function AccountingPrivateRoute({ children }) {
	const { user: authUser } = useSelector((x) => x.auth);
	
		
	if (!authUser) {
		return <Navigate to="/" state={{ from: history.location }} />;
	}
	return (
		<>
			<AccountingLayout>{(authUser && authUser?.accounting !== 1) ? <h1 className="mt-2">Not Allowed!</h1> : children}</AccountingLayout>
		</>
	);

}
