import React from 'react';

const LoaderComponent = () => {
  return (
    <div id="loader">
      {/* <img src={"../../images/images/media/loader.svg"} alt="" /> */}
      <button class="btn btn-primary-light" type="button" disabled>
        <span
          class="spinner-border spinner-border-sm align-middle"
          role="status"
          aria-hidden="true"
        />
        Loading...
      </button>
    </div>
  );
};

export default LoaderComponent;
