import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { history } from "_helpers";
import AffiliateLayout from "./affiliate/layout/AffiliateLayout";

export { AffiliatePrivateRoute };

function AffiliatePrivateRoute({ children }) {
	const { user: authUser } = useSelector((x) => x.auth);
	
		
	if (!authUser) {
		return <Navigate to="/" state={{ from: history.location }} />;
	}
	return (
		<>
			<AffiliateLayout>{(authUser && authUser?.affiliate_approved !== 1) ? <h1 className="mt-2">Not Allowed!</h1> : children}</AffiliateLayout>
		</>
	);

}
