import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Sidebar = ({ sidebarLinks }) => {
  const { user: authUser } = useSelector((state) => state.auth);

  return (
    <aside className="app-sidebar sticky" id="sidebar">
      {/* Sidebar header with logo */}
      <div className="main-sidebar-header">
        <span className="header-logo" style={{ cursor: 'pointer' }}>
          <img
            src="../assets/images/logo.png"
            alt="logo"
            width={100}
            className="desktop-logo"
          />
        </span>
      </div>
      {/* Sidebar content based on the user's role */}
      <div className="main-sidebar">
        <nav className="main-menu-container nav nav-pills flex-column sub-open">
          <ul className="main-menu mt-2">
            {Object.keys(sidebarLinks).map((type) =>
              sidebarLinks[type].map((link, index) => {
                if (link.showSidebar) {
                  if (link.isConditional && authUser[link.ConditionKey] === 1) {
                    return (
                      <li className="slide" key={index}>
                        <NavLink to={`/${type}${link.path}`} className="side-menu__item">
                          <i className={`${link.icon} side-menu__icon`} />
                          <span className="side-menu__label">{link.label}</span>
                        </NavLink>
                      </li>
                    );
                  }
                  if (!link.isConditional) {
                    return (
                      <li className="slide" key={index}>
                        <NavLink to={`/${type}${link.path}`} className="side-menu__item">
                          <i className={`${link.icon} side-menu__icon`} />
                          <span className="side-menu__label">{link.label}</span>
                        </NavLink>
                      </li>
                    );
                  }
                }
                return null;
              })
            )}
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;
