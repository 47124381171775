import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { logout } from '_store/login.slice';
import { setActiveProfile } from '_store/users.slice';
const SideBarOffCanvas = () => {
  const dispatch = useDispatch();
  // const activeProfile = useSelector((state) => state.users.profileActive);
  // const { company } = useSelector((state) => state.company.profile);
  const logoutUser = () => dispatch(logout());
  const { user: authUser } = useSelector(state => state.auth);

  return (
    <div
      className="offcanvas offcanvas-end offcanvas-sidebar overflow-auto"
      tabIndex={-1}
      id="offcanvassidebar"
    >
      {/* Sidebar-right */}
      <div className="card custom-card tab-heading shadow-none">
        <div className="card-body p-0">
          <ul
            className="nav nav-tabs my-3 nav-style-1 justify-content-center"
            role="tablist"
          >
            <li className="nav-item col-md-12 text-center">
              <h6 className="fw-semibold  mt-2 mb-0">{authUser?.full_name}</h6>
              <br />
            </li>
            {/* <li className="nav-item">
              <a
                className="nav-link active"
                data-bs-toggle="tab"
                role="tab"
                href="#home31"
                aria-selected="true"
              >
                <i className="fe fe-user" />
                Manage Profile
              </a>
            </li> */}

          </ul>
          <div className="tab-content">
            <div
              className="tab-pane show active text-muted p-0 rounded-0 border-bottom-0 border-end-0"
              id="home31"
              role="tabpanel"
            >

              {(authUser?.affiliate_approved === 1 || authUser?.accounting === 1 || authUser?.administrator === 1) &&
                <div className="dropdown-item d-flex border-bottom">
                  <NavLink to="/client/passes" className="side-menu__item">
                    <div className="d-flex">
                      <i className="fe fe-das me-3 fs-20 text-muted" />
                      <div className="pt-1">
                        <h6 className="mb-0">Client Portal</h6>
                      </div>
                    </div>
                  </NavLink>
                </div>
              }

              {authUser?.affiliate_approved === 1 &&
                <div className="dropdown-item d-flex border-bottom">
                  <NavLink
                    to="/affiliate/dashboard"
                    className="side-menu__item"
                  >
                    <div className="d-flex">

                      <i className="fe fe-das me-3 fs-20 text-muted" />
                      <div className="pt-1">
                        <h6 className="mb-0">Affiliate Portal</h6>
                      </div>
                    </div>

                  </NavLink>
                </div>}

              {authUser?.accounting === 1 &&
                <div className="dropdown-item d-flex border-bottom">
                  <NavLink
                    to="/accounting/dashboard"
                    className="side-menu__item"
                  >
                    <div className="d-flex">

                      <i className="fe fe-das me-3 fs-20 text-muted" />
                      <div className="pt-1">
                        <h6 className="mb-0">Accounting Portal</h6>
                      </div>
                    </div>

                  </NavLink>
                </div>}

              {authUser?.administrator === 1 &&
                <div className="dropdown-item d-flex border-bottom">
                  <NavLink to="/admin/dashboard" className="side-menu__item">
                    <div className="d-flex">

                      <i className="fe fe-das me-3 fs-20 text-muted" />
                      <div className="pt-1">
                        <h6 className="mb-0">Admin Portal</h6>
                      </div>
                    </div>

                  </NavLink>
                </div>}

              <div
                className="dropdown-item d-flex border-bottom"
                style={{ cursor: 'pointer' }}
                onClick={() => logoutUser()}
              >
                <div className="d-flex">
                  <i className="fe fe-power me-3 fs-20 text-muted" />
                  <div className="pt-1">
                    <h6 className="mb-0">Sign Out</h6>
                    <p className="fs-12 mb-0 text-muted">
                      Account Signout
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*/Sidebar-right*/}
    </div>
  );
};

export default SideBarOffCanvas;
